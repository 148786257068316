import React from 'react'
// import styled from 'styled-components'
// import img from "../../Assets/download.svg"


const Footer = () => {
  return (
    <div>
      {/* <Container style={{backgroundImage : `url(${img})`}}>
        <Main>
        
        </Main>
      </Container> */}
    </div>
  )
}

export default Footer

// const Main=styled.div`
//  background-color: red; 
// height: 90%;
// width: 80%;
// display: flex;
// align-items: center;
// justify-content: center;
// flex-direction: column;
// `
// const Container=styled.div`
//   width: 100%;
// padding-bottom: 150px;
//   background-size: cover;
//   background-attachment:fixed ;
//   /* margin-top: 300px; */
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   `;